import * as Sentry from '@sentry/nextjs';

// Define a custom error type for more specific error handling
export interface CustomError extends Error {
  statusCode?: number;
}

export const errorHandler = ({
  error,
  logMessage = '*** Error ***',
  data = {},
  tags = { threat_level: 'not midnight' },
  throwErr = false,
}: {
  error: CustomError | unknown;
  logMessage?: string;
  data?: object;
  tags?: object;
  throwErr?: boolean;
}) => {
  console.error(logMessage, error);

  Sentry.captureException(error, {
    extra: { ...data }, // Optionally attach additional data for more context
    tags: { ...tags }, // Optionally assign tags to the event { label: 'value' }
  });

  if (throwErr) {
    throw error;
  }
};
